@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.bg-components {
  @apply bg-gray-100 dark:bg-gradient-to-r dark:from-[#09203F] dark:to-[#537895];
}

.page-header {
  @apply px-8 lg:px-32 pt-20 pb-10 shadow-xl dark:bg-gradient-to-tl dark:from-[#B8CEE9] bg-white;
}

.custom-shadow {
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

button {
  @apply hover:scale-105 hover:ease-in-out hover:duration-300;
}

#app {
  height: 100%;
}

html,
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  z-index: 10;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  text-align: center;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: #cbcbcb;
}

.swiper-pagination-bullet-active {
  color: #0b1629;
  background: #0b1629;
}
